<template>
  <div class="page-wrapper" v-loading="loading">
    <el-row :gutter="10">
      <el-col :sm="24" :md="12" v-for="section in sections" :key="section.id">
        <el-card class="box-card" shadow="never">
          <div slot="header" class="clearfix">
            <span>网站配置</span>
            <el-button
              style="float: right; padding: 3px 0"
              type="text"
              @click="onSave"
              >保存</el-button
            >
          </div>
          <el-form ref="form" :model="section" label-width="120px">
            <el-form-item label="LOGO">
              <SingleImgUpload class="logo" v-model="section.imageUrl" />
            </el-form-item>
            <el-form-item label="网站标题">
              <el-input v-model="section.title"></el-input>
            </el-form-item>
            <el-form-item label="keywords">
              <el-input v-model="section.subTitle"></el-input>
            </el-form-item>
            <el-form-item label="description">
              <el-input v-model="section.description"></el-input>
            </el-form-item>
            <!-- <el-form-item label="版权信息">
              <el-input v-model="section.content"></el-input>
            </el-form-item> -->
            <!-- <el-form-item label="全局水印">
              <SingleImgUpload class="watermark" v-model="section.icon" />
            </el-form-item> -->
          </el-form>
        </el-card>
      </el-col>
     
    </el-row>
  </div>
</template>

<script>
import {
  GetPagesById,
  GetPagesSectionsById,
  PutPagesById,
} from "@/views/pagesManager/api";
import SingleImgUpload from "@/components/SingleImgUpload";
import { ShowApiError } from "@/request/error";
export default {
  components: { SingleImgUpload },
  data() {
    return {
      loading: true,
      submitting: false,
      currentPageId: 5,
      sections: [],
      page: {
        name: "",
        parentId: null,
        title: "",
        subTitle: "",
        description: "",
        icon: "",
        sections: [],
        seq: null,
        hidden: null,
      },
    };
  },
  created() {
    this.initSetting();
  },
  methods: {
    initSetting() {
      this.loading = true;
      GetPagesById(this.currentPageId)
        .then((res) => {
          for (const key in res.data) {
            if (Object.hasOwnProperty.call(this.page, key)) {
              this.page[key] = res.data[key];
            }
          }
          return GetPagesSectionsById(this.currentPageId);
        })
        .then((res) => {
          this.sections = res.data;
        })
        .catch((err) => {
          ShowApiError(err, "获取页面信息失败");
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onSave() {
      let _form = {
        ...this.page,
        sections: [...this.sections],
      };
      this.submitting = true;
      PutPagesById(this.currentPageId, _form)
        .then(() => {
          this.$message.success("保存成功");
          this.initSetting();
        })
        .catch((err) => {
          ShowApiError(err, "保存失败");
        })
        .finally(() => {
          this.submitting = false;
        });
      // console.log("提交的表单", _form);
    },
  },
};
</script>

<style lang="scss" scoped>
.logo {
  padding: 5px;
  ::v-deep {
    .el-image {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .el-image__inner {
      max-width: 100%;
      max-height: 100%;
    }
  }
}
.page-wrapper {
  box-sizing: border-box;
  padding: 10px;
  width: 100%;
  height: 100%;
  background-color: #fff;
  overflow-x: hidden;
  overflow-y: auto;
}
.box-card {
  margin: 10px 0;
}
.watermark {
  width: 100px;
  height: 100px;
  background-color: #333;
  &:hover {
    background-color: #000;
  }
}
</style>
